// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links


// Horrible membership js
$("body[class*='membership-yearly'] #donationPeriod > option[value='Monthly']").attr("selected", false);
$("body[class*='membership-yearly'] #donationPeriod > option[value='Quarterly']").attr("selected", false);
$("body[class*='membership-yearly'] #donationPeriod > option[value='Yearly']").attr("selected", "selected");

$("body[class*='membership-monthly'] #donationPeriod > option[value='Yearly']").attr("selected", false);
$("body[class*='membership-monthly'] #donationPeriod > option[value='Quarterly']").attr("selected", false);
$("body[class*='membership-monthly'] #donationPeriod > option[value='Monthly']").attr("selected", "selected");

// 1053763 SA - On mobile view, moving the MEMBERSHIP, SHOP and DONATE CTA buttons in the Menu dropdown to just beneath the search bar
$(document).ready(function() {
  if (window.matchMedia("(max-width: 1024px)").matches) {
    $("ul.topLevel.level1").insertAfter("header#pageHeader .menuMain .mainCallToAction div.mainCallToActionButtons");
  }
});